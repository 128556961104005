import styled from "@emotion/styled";
import { css } from "@linaria/atomic";
import { Grid } from "@mui/material";
import LinkButton from "../LinkButton";
import CardImageSrc from "../../assets/banner_image.png";
import BGImageSrc from "../../assets/home_bg.png";
// import HandImageSrc from "../../assets/hand.png";
// import EvrerfImageSrc from "../../assets/evrerf.png";
// import PecypcImageSrc from "../../assets/pесурс.png";
import useWindowDimensions from "../../utils/windowDimensions";

const Container = styled.div`
  background-image: url(${BGImageSrc});
  background-size: cover;
  background-position: right;
  width: 100%;
  height: 100%;
  min-height: min(calc(${(props) => props.height}px + 70px), 600px);

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  padding-top: 150px;

  @media screen and (min-width: 768px) and (max-width: 1199px) {
    padding-top: 50px;
  }
`;

const LeftView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 1024px) {
    align-items: center;
  }
`;

const RightView = styled.div`
  @media (max-width: 1024px) {
    width: 100%;
    max-width: 560px;
    display: flex;
    position: relative;
  }
`;

const Title = styled.div`
  color: #ffffff;
  font-family: "Raleway", Sans-serif;
  font-size: 82px;
  font-weight: 600;
  line-height: 1.1em;
  letter-spacing: 0px;
  text-align: left;

  @media (max-width: 1024px) {
    text-align: center;
    font-size: 64px;
  }

  @media (max-width: 768px) {
    font-size: 40px;
    max-width: 500px;
  }
`;

const Description = styled.div`
  font-size: 30px;
  color: #2cbca5;
  max-width: 900px;
  line-height: 1.9em;
  margin-top: 20px;
  margin-bottom: 66px;
  text-align: left;
  @media (max-width: 900px) {
    font-size: 16px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  font-family: "DM Sans", sans-serif;

  .right-border {
    padding-right: 20px;
    border-right: 1px solid #fff;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding-top: 10px;

    .right-border {
      padding-right: 0;
      border-right: none;
    }
  }
`;

const InfoItem = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const InfoNumber = styled.div`
  font-size: 46px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
`;

const InfoLabel = styled.div`
  font-size: 14px;
  color: #b3b4b7;
  font-weight: 600;
  text-transform: uppercase;
`;

const ImageContainer = styled.div`
  width: 100%;
  align-content: center;
  align-items: center;
  margin: 0;
  display: flex;
  position: relative;
  box-sizing: border-box;
`;

const CardWrapper = styled.div`
  max-width: 550px;
  max-height: 550px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;

  @media (max-width: 1024px) {
    max-width: 400px;
    margin-top: 0;
  }

  @media (max-width: 767px) {
    max-width: 90%;
  }
`;

const CommonImageContainer = styled.div`
  width: auto;
  position: absolute;
  margin: 0;
  text-align: center;
`;

const Evrerf1ImageContainer = styled(CommonImageContainer)`
  left: 15%;
  top: 83px;

  @media (max-width: 767px) {
    left: 0;
    top: 0;
  }
`;

const Evrerf2ImageContainer = styled(CommonImageContainer)`
  top: 70%;
  right: -95px;

  @media (max-width: 1024px) {
    top: 50%;
    right: 0px;
  }

  @media (max-width: 767px) {
    top: 90%;
    right: 0;
  }
`;

const PecypcImageContainer = styled(CommonImageContainer)`
  top: 83px;
  left: 77%;

  @media (max-width: 767px) {
    top: 0;
  }
`;

const EvrerfImageWrapper = styled.div`
  width: 60px;

  @media (max-width: 1024px) {
    width: 40px;
  }

  @media (max-width: 767px) {
    width: 30px;
  }
`;

const Evrerf2ImageWrapper = styled(EvrerfImageWrapper)`
  @media (max-width: 767px) {
    width: 25px;
  }
`;

const PecypcImageWrapper = styled.div`
  width: 40px;

  @media (max-width: 1024px) {
    width: 20px;
  }
`;

const CardImage = css`
  max-width: 100%;
  max-height: 100%;
`;

// @keyframes float {
//   0% {
//     transform: translatey(20px);
//   }
//   50% {
//     transform: translatey(-10px);
//   }
//   100% {
//     transform: translatey(20px);
//   }
// }

// animation: float 4s ease-in-out infinite;
const HandImage = css`
  width: 40%;
  max-width: 1024px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const EvrerfImageClass = css`
  width: 100%;
  aspect-ratio: auto 114 / 115;
`;

const PecypcImageClass = css`
  width: 100%;
  aspect-ratio: auto 114 / 115;
`;

const HeroSection = () => {
  const windowDimensions = useWindowDimensions();

  return (
    <div style={{ width: "100%" }}>
      <Container height={windowDimensions.height}>
        <Grid
          container
          style={{ maxWidth: "1200px", margin: "0 10px" }}
          sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
          spacing={2}
        >
          <Grid container item xs={12} md={6}>
            <LeftView>
              <Title>Proof of your creation in blockchain</Title>
              <Description>Protect copyright and prove ownership</Description>
              <div
                style={{
                  display: "flex",
                }}
              >
                <LinkButton
                  to="/create"
                  style={{ zIndex: 99 }}
                  noGrowEffect={false}
                >
                  Create now
                </LinkButton>
              </div>
            </LeftView>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              padding: 0,
              display: "flex",
              justifyContent: "center",
              marginTop: "-60px",
            }}
          >
            <RightView>
              <ImageContainer>
                {/* <Evrerf1ImageContainer>
                  <EvrerfImageWrapper>
                    <img
                      alt="Evrerf1"
                      className={EvrerfImageClass}
                      src={EvrerfImageSrc}
                    />
                  </EvrerfImageWrapper>
                </Evrerf1ImageContainer>
                <Evrerf2ImageContainer>
                  <Evrerf2ImageWrapper>
                    <img
                      alt="Evrerf2"
                      className={EvrerfImageClass}
                      src={EvrerfImageSrc}
                    />
                  </Evrerf2ImageWrapper>
                </Evrerf2ImageContainer>
                <PecypcImageContainer>
                  <PecypcImageWrapper>
                    <img
                      alt="Pecypc"
                      className={PecypcImageClass}
                      src={PecypcImageSrc}
                    />
                  </PecypcImageWrapper>
                </PecypcImageContainer> */}
                <CardWrapper>
                  <img alt="Sample" className={CardImage} src={CardImageSrc} />
                </CardWrapper>
              </ImageContainer>
            </RightView>
          </Grid>
        </Grid>
        {/* <div
        style={{
          position: "absolute",
          top: "501px",
          right: "0",
          textAlign: "right",
          width: "100%",
          marginTop: "125px",
        }}
      >
        <img alt="Hand" className={HandImage} src={HandImageSrc} />
      </div> */}
      </Container>
      {/* <InfoWrapper>
        <InfoItem className="right-border">
          <InfoNumber>200+</InfoNumber>
          <InfoLabel>Users</InfoLabel>
        </InfoItem>
        <InfoItem>
          <InfoNumber>500+</InfoNumber>
          <InfoLabel>Creations Protected</InfoLabel>
        </InfoItem>
      </InfoWrapper> */}
    </div>
  );
};

export default HeroSection;
