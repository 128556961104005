import MuiTextField from "@mui/material/TextField";
import Button from "./Button";
import { Grid } from "@mui/material";
import styled from "@emotion/styled";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
`;

const Label = styled.div`
  color: #8d8e92;
  font-size: 16px;
  font-family: "Inter", sans-serif;
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: center;

  @media (max-width: calc(540px - 1px)) {
    flex-direction: column;
  }
`;

const ButtonWrapper = styled.div`
  @media (max-width: calc(540px - 1px)) {
    width: 100%;
  }
`;

const StyledTextField = styled(MuiTextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#313743",
    borderRadius: "16px",
    borderColor: "#313743",
    color: "white",
    "& .Mui-disabled": {
      "-webkit-text-fill-color": "rgb(255,255,255,0.5)",
    },
    "& fieldset": {
      transition: "all 450ms",
    },
    "&:hover fieldset": {
      borderColor: "#8d8e92",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#8d8e92",
      borderWidth: "1px",
    },
  },
});

const TextField = (props) => {
  const { buttonLabel = "", buttonOnClick } = props;

  return (
    <Container>
      <Label>{props.label}</Label>
      <ContentContainer>
        <StyledTextField
          style={{
            width: "100%",
            ...props.style,
          }}
          {...props}
          label={null}
          multiline={props.multiline}
          rows={props.multiline ? props.rows || 4 : 1}
          variant="outlined"
        />
        {buttonLabel !== "" && (
          <ButtonWrapper>
            <Button sx={{ width: 240, height: 50 }} onClick={buttonOnClick}>
              {buttonLabel}
            </Button>
          </ButtonWrapper>
        )}
      </ContentContainer>
    </Container>
  );
};

export default TextField;
