import Button from "../components/Button";
import TextField from "../components/TextField";
import { styled } from "@linaria/react";
import { cx } from "@linaria/core";
import { css } from "@linaria/atomic";
import {
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
  Paper,
} from "@mui/material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "../components/Dialog";
import { useEffect, useRef, useState } from "react";
import constants from "../constants";
import Space from "../components/Space";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import decrypt from "../utils/decrypt";
import {
  FormContainer,
  FormTitle,
  FormSubTitle,
  FormQuestionContainer,
} from "../components/Form";
import Switch from "../components/Switch";

const Label = styled.div`
  color: #8d8e92;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  text-align: left;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0 30px;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const RecordDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const passcodeRef = useRef(null);
  const [searchParams] = useSearchParams();
  const [record, setRecord] = useState(null);
  const [passcode, setPasscode] = useState(null);
  const [passcodeError, setPasscodeError] = useState(false);
  const [encrypt, setEncrypt] = useState(null);

  useEffect(() => {
    async function getRecord() {
      const response = await fetch(
        `${constants.API_ENDPOINT}/order/transaction/${id}`
      );
      const result = await response.json();
      setRecord(result.data);
      if (result.data.encrypted) {
        setEncrypt(result.data.encrypted);
      }
    }
    getRecord();
  }, []);

  const handleDecrypt = () => {
    setPasscode(passcodeRef.current.value);
    const data = decrypt(encrypt, passcodeRef.current.value);
    try {
      const json = JSON.parse(data);
      console.log("json", json);
      if (json) {
        setRecord({ ...record, ...json });
        setEncrypt(null);
      } else {
        setPasscodeError(true);
      }
    } catch (error) {
      console.log("error", error);
      setPasscodeError(true);
    }
  };

  const saveAs = (blob) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "download";
    document.body.appendChild(a);
    a.click();
  };
  const saveAsByDataUrl = (url) => {
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "download";
    document.body.appendChild(a);
    a.click();
  };
  const downloadFile = (url) => {
    if (record.encrypted) {
      fetch(url)
        .then((r) => r.text())
        .then((data) => {
          if (data) {
            let raw = decrypt(data, passcode);
            saveAsByDataUrl(raw);
          }
        });
    } else {
      fetch(url)
        .then((r) => r.blob())
        .then((blob) => {
          saveAs(blob);
          window.URL.revokeObjectURL(url);
        });
    }
  };

  const saveInfoAsFile = () => {
    var blob = new Blob(
      [
        "Transaction ID: " +
          id +
          "\nEncryption: " +
          (record.encrypted ? "true" : "false") +
          "\nFile path: " +
          record.file +
          "\nTimestamp: " +
          dayjs(record.timestamp * 1000).format("DD/MM/YYYY HH:mm") +
          "\nDescription: " +
          record.description +
          "\nName: " +
          record.name +
          "\nEmail: " +
          record.email +
          "\nPhone: " +
          record.phone,
      ],
      { type: "text/plain;charset=utf-8" }
    );
    saveAs(blob);
  };

  const sendEmail = () => {
    window.open(
      "mailto:?subject=subject&body=" +
        "Transaction ID: " +
        id +
        "\nEncryption: " +
        record.encrypted
        ? "true"
        : "false" +
            "\nFile path: " +
            record.file +
            "\nTimestamp: " +
            dayjs(record.timestamp * 1000).format("DD/MM/YYYY HH:mm") +
            "\nDescription: " +
            record.description +
            "\nName: " +
            record.name +
            "\nEmail: " +
            record.email +
            "\nPhone: " +
            record.phone
    );
  };

  console.log(record);
  if (encrypt)
    return (
      <Dialog open={encrypt} aria-labelledby="form-dialog-title">
        <DialogContent>
          {searchParams.get("success") ? (
            <>
              <FormTitle>
                {searchParams.get("success")
                  ? "Congratulations! A proof of your creation is written in blockchain."
                  : "Proof retrieval from blockchain"}
              </FormTitle>
              <Label>
                To view the transaction record, please input your passcode to
                proceed.
              </Label>
            </>
          ) : (
            <Typography
              id="form-dialog-title"
              style={{ whiteSpace: "pre-line" }}
            >
              The data is encrypted, please input passcode to unlock the data.
            </Typography>
          )}

          <TextField
            inputRef={passcodeRef}
            fullWidth
            autoFocus
            error={passcodeError}
            helperText={passcodeError ? "Invalid passcode" : ""}
            label="Passcode"
            placeholder="Enter your passcode here"
            type="string"
            name="passcode"
          />
        </DialogContent>
        <DialogActions>
          <ActionButtonContainer>
            <Button
              theme="secondary"
              onClick={() => navigate("/record", { replace: true })}
            >
              Cancel
            </Button>
            <Button onClick={handleDecrypt}>OK</Button>
          </ActionButtonContainer>
        </DialogActions>
      </Dialog>
    );
  if (!record) return <div />;
  return (
    <Container>
      <FormContainer>
        <FormTitle>
          {searchParams.get("success")
            ? "Congratulations! A proof of your creation is written in blockchain."
            : "Proof retrieval from blockchain"}
        </FormTitle>
        {searchParams.get("success") && (
          <Label>
            The proof detail is shown below. Transaction ID is a unique
            identifier for the proof of your creation on blockchain. You have to
            save this ID so you could retrieve the proof and show to others in
            future. In case you enabled the encryption, make sure you have
            written down the passcode.
          </Label>
        )}
        <FormQuestionContainer>
          <TextField
            disabled={true}
            label="Transaction ID"
            name="transactionHash"
            value={id}
            buttonLabel="View in explorer"
            buttonOnClick={() =>
              window.open(constants.SCAN_ENDPOINT + id, "_blank")
            }
          />
          {/* <TextField
            disabled={true}
            label="File"
            name="file"
            value={record.file}
            buttonLabel="Download"
            buttonOnClick={() => downloadFile(record.file)}
          /> */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Label>File</Label>
            <Button
              sx={{
                alignContent: "flex-sta",
                width: 200,
                height: 50,
                marginTop: 1,
              }}
              onClick={() => downloadFile(record.file)}
            >
              Download
            </Button>
          </div>
          <TextField
            disabled={true}
            label="Timestamp (The time on blockchain at which the proof was written)"
            name="updatedAt"
            value={dayjs(record.timestamp * 1000).format("DD/MM/YYYY HH:mm")}
          />
          <TextField
            disabled={true}
            multiline
            minRows={3}
            label="Description"
            name="description"
            value={record.description}
          />
        </FormQuestionContainer>

        <FormSubTitle>Creator Info</FormSubTitle>
        <FormQuestionContainer>
          <TextField
            disabled={true}
            label="Name"
            name="name"
            value={record.name}
          />
          <TextField
            disabled={true}
            label="Email"
            name="email"
            value={record.email}
          />
          <TextField
            disabled={true}
            label="Phone"
            name="phone"
            value={record.phone}
          />
        </FormQuestionContainer>

        {/* <FormQuestionContainer>
          <Switch
            checked={passcode !== null}
            disabled={true}
            label={"Encryption"}
          />
          {passcode !== null && (
            <TextField
              disabled={true}
              label="Passcode"
              name="passcode"
              value={passcode}
            />
          )}
        </FormQuestionContainer> */}

        {/* {searchParams.get("success") && (
          <ActionButtonContainer>
            <Button onClick={saveInfoAsFile}>Save the proof to file</Button>
            <Button onClick={sendEmail}>Email the proof</Button>
          </ActionButtonContainer>
        )} */}
      </FormContainer>
    </Container>
  );
};
export default RecordDetail;
