import { Typography, CircularProgress, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Space from "../components/Space";
import constants from "../constants";
import Button from "../components/Button";
import styled from "@emotion/styled";

const PageContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 30px;
  justify-content: center;
`;

const Container = styled.div`
  background-color: #23262f;
  border-radius: 10px;
  padding: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
  max-width: 700px;

  @media (max-width: calc(768px - 1px)) {
    padding: 30px 20px;
  }
`;

const Title = styled.div`
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
`;

const Description = styled.div`
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  color: #ffffff7a;
  font-weight: 400;
  line-height: 1.6em;
`;

const UploadSuccess = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  useEffect(() => {
    async function pollingOrderStatus() {
      // polling order status
      const response = await fetch(
        `${constants.API_ENDPOINT}/order/${id}/check`,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      const result = await response.json();
      if (result.data?.email) setEmail(result.data?.email);
      if (result.data?.ready) {
        navigate(`/record/${result.data.transactionHash}?success=true`, {
          replace: true,
        });
      } else {
        setTimeout(pollingOrderStatus, 10000);
      }
    }
    pollingOrderStatus();
  }, []);

  return (
    <PageContainer>
      <Container>
        <Title>Payment success! Thank you for the purchase</Title>
        <Title>Your payment ID is {id}</Title>
        <CircularProgress style={{ padding: "20px 0" }} />
        <Description>
          The proof is being written to the blockchain. It may take several
          minutes to complete.
        </Description>
        <Description>
          You may leave this page or wait until the transaction is ready. In any
          case, we will send you an email ({email}) regarding the transaction
          record.
        </Description>
        <Description>
          If you have any problem in getting the transaction record, feel free
          to contact our support.
        </Description>
        {/* <Button onClick={() => (window.location = "/")}>
          Leave (and wait for email)
        </Button> */}
      </Container>
    </PageContainer>
  );
};
export default UploadSuccess;
