import { useRef } from "react";
import Button from "./Button";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";

const ButtonForUpload = styled.div`
  font-family: "Inter", Sans-serif;
  font-size: 16px;
  color: #ffffff;
  background-color: #36bb91;
  height: 57px;
  width: 200px;
  line-height: 57px;
  border-radius: 14px;
  transition: all 450ms;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: #1a5e49;
  }
`;

const FileUploadComponent = (props) => {
  const { onChange, value, error, helperText, disabled } = props;
  const inputEl = useRef(null);

  return (
    <div
      style={{
        display: "flex",
        with: "100%",
        alignItems: "center",
        gap: "30px",
        color: "#fff",
        minHeight: "46px",
      }}
    >
      {!disabled && !value && (
        <>
          <ButtonForUpload onClick={() => inputEl.current.click()}>
            Upload
            <input type="file" ref={inputEl} hidden onChange={onChange} />
          </ButtonForUpload>
          {error && (
            <div style={{ color: "#d32f2f", fontSize: "12px" }}>
              {helperText}
            </div>
          )}
        </>
      )}
      {value && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#313743",
            borderRadius: "16px",
            borderColor: "#313743",
            color: disabled ? "rgb(255, 255, 255, 0.5)" : "white",
            height: 57,
            padding: "0px 14px",
          }}
        >
          {value.name}
          {!disabled && (
            <CloseIcon
              style={{ cursor: "pointer" }}
              color={"primary"}
              onClick={() => onChange(null)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default FileUploadComponent;
