/* eslint-disable */
import "./App.css";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { css } from "@linaria/atomic";
import styled from "@emotion/styled";
import Upload from "./pages/Upload";
import Record from "./pages/Record";
import RecordDetail from "./pages/RecordDetail";
import PaymentSuccess from "./pages/PaymentComplete";
import PaypalPayment from "./pages/PaypalPayment";
import EmailRecord from "./pages/EmailRecord";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Layout from "./components/Layout";
import ScrollToTop from "./utils/scrollToTop";
import ReactGA from "react-ga4";

ReactGA.initialize("G-7FRGLE4XN0");
const HeaderClass = css`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px;
  background: linear-gradient(180deg, rgb(0, 0, 0, 1) 0%, rgb(0, 0, 0, 0) 100%);
`;

const LinkClass = css`
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin-right: 20px;
`;

const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    max-height: ${(props) => (props.isExpand ? "100vh" : "none")};
    overflow: hidden;
  }
`;

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #000;
`;

function App() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container isExpand={isOpen}>
      <BrowserRouter>
        <ScrollToTop />
        <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
        <PageWrapper>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route
              exact
              path="/create"
              element={
                <Layout>
                  <Upload />
                </Layout>
              }
            />
            <Route
              exact
              path="/payment-complete/:id"
              element={
                <Layout>
                  <PaymentSuccess />
                </Layout>
              }
            />
            <Route
              exact
              path="/record"
              element={
                <Layout>
                  <Record />
                </Layout>
              }
            />
            <Route
              exact
              path="/record/:id"
              element={
                <Layout>
                  <RecordDetail />
                </Layout>
              }
            />
            <Route
              exact
              path="/email"
              element={
                <Layout>
                  <EmailRecord />
                </Layout>
              }
            />
            <Route
              exact
              path="/pay/:id"
              element={
                <Layout>
                  <PaypalPayment />
                </Layout>
              }
            />
          </Routes>
          <Footer />
        </PageWrapper>
      </BrowserRouter>
    </Container>
  );
}

export default App;
