// DEV
// export default {
//   API_ENDPOINT: "https://gpclqaqz6b.execute-api.ap-east-1.amazonaws.com/dev",
//   ENCRYPTION_KEY: "ZVfbyOOsYXpVmFQRVGL2OSGMs3lcV8tK",
//   SCAN_ENDPOINT: "https://mumbai.polygonscan.com/tx/",
//   SAMPLE_TX:
//     "0x1a98f7f12a7a3f714afefeb0c0553e10a6394604eaeefa98ff031135a18531b9",
//   PAYPAL_CLIENT_ID:
//     "ASQCIpQC7V_xp3sAUYc-_jP4T-Azs9lh_w-0aOgyR-2tHE7YL8-8Ajlg9K0qs7rvaKuThHVnPpwbWaFy",
// };
// PROD
export default {
  API_ENDPOINT:
    "https://lretz8fsb2.execute-api.ap-east-1.amazonaws.com/production",
  ENCRYPTION_KEY: "ZVfbyOOsYXpVmFQRVGL2OSGMs3lcV8tK",
  SCAN_ENDPOINT: "https://polygonscan.com/tx/",
  SAMPLE_TX:
    "0x8d48d18e6f8e094a4f62e7467c663f9fb43904a7dea36c4fdade62ef7639bdbb",
  PAYPAL_CLIENT_ID:
    "Ae2mel7l1ccqWk-FnV3NFwjcpEE9wTn90mG4lIN9Xh6eHLzB0tCNw9Gt-9LrwVnh9fK4e93_6JBDEC8F",
};
