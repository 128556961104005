import styled from "@emotion/styled";
import MuiButton from "@mui/material/Button";
import { Link } from "react-router-dom";

const CustomButton = styled(MuiButton)`
  font-family: "Raleway", Sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #fff;
  background-color: ${({ theme }) =>
    theme === "secondary" ? "#1c1e24" : "#36BB91"};
  padding: 17px 50px;
  border-radius: 10px;
  transition: all 450ms;
  cursor: pointer;
  text-decoration: none;
  text-transform: none;

  &:hover {
    background-color: ${({ theme }) =>
      theme === "secondary" ? "#16181d" : "#1a5e49"};
  }
`;

const Button = (props) => {
  const { label, onClick, color, children } = props;

  return (
    <CustomButton fullWidth onClick={onClick} theme={color} {...props}>
      {label}
      {children}
    </CustomButton>
  );
};

export default Button;
