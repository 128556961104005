import styled from "@emotion/styled";
import UploadForm from "../components/UploadForm";

const Container = styled.div`
    display: flex;
    width: 100%;
    padding: 0 30px;
`

const UploadPage = () => {
    return (
        <Container>
            <UploadForm />
        </Container>
    )
}

export default UploadPage;