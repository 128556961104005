import { useState } from "react";
import Button from "../components/Button";
import TextField from "../components/TextField";
import { styled } from "@linaria/react";
import { useRef } from "react";
import constants from "../constants";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  FormContainer,
  FormTitle,
  FormSubTitle,
  FormQuestionContainer,
} from "../components/Form";
import CircularProgress from "@mui/material/CircularProgress";

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0 30px;
`;

const Record = () => {
  const navigate = useNavigate();
  let transactionInputRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleRetreive = async () => {
    if (transactionInputRef.current.value === "") {
      setError("Please enter transaction ID");
      return;
    }
    setIsLoading(true);
    setError("");
    const id = transactionInputRef.current.value;

    await fetch(`${constants.API_ENDPOINT}/order/transaction/${id}`)
      .then((res) => {
        if (res.status !== 200) throw new Error("Error");
        return res.json();
      })
      .then((res) => {
        if (res?.data) navigate("/record/" + id, { replace: true });
      })
      .catch((err) => {
        console.log(err);
        setError("Transaction ID not found");
        setIsLoading(false);
      });
  };

  return (
    <Container>
      <FormContainer>
        <FormTitle>Proof retrieval from blockchain</FormTitle>
        {isLoading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <FormQuestionContainer>
              <TextField
                label="Transaction ID"
                name="transactionHash"
                inputRef={transactionInputRef}
                error={error !== "" ? true : false}
                helperText={error !== "" ? error : null}
              />
            </FormQuestionContainer>
            <Button onClick={handleRetreive}>Retreive</Button>
          </>
        )}
      </FormContainer>
    </Container>
  );
};

export default Record;
