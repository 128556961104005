import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const Button = styled(Link)`
  font-family: "Raleway", Sans-serif;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #ffffff;
  background-color: #36bb91;
  padding: 17px 50px;
  border-radius: 10px;
  box-shadow: ${({ noGrowEffect }) =>
    noGrowEffect ? "none" : "0px 10px 20px 0px rgb(54 187 145 / 28%)"};
  transition: all 450ms;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: #1a5e49;
  }
`;

const StyledLinkButton = (props) => {
  const { label = "", to = "", children, noGrowEffect = true } = props;

  return (
    <div style={{ display: "flex" }}>
      <Button
        to={to}
        noGrowEffect={noGrowEffect}
        style={{ ...props.style, textWrap: "nowrap" }}
      >
        {label || children}
      </Button>
    </div>
  );
};

export default StyledLinkButton;
