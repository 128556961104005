import MuiSwitch from '@mui/material/Switch';
import styled from "@emotion/styled";

const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-align: left;
`

const Switch = (props) => {
    const { checked, onChange, label } = props;

    return (
        <Container>
            <div>{label}</div>
            <MuiSwitch 
                checked={checked}
                onChange={onChange}
                color="primary"
                {...props}
            />
        </Container>
    )
}

export default Switch;