import { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@linaria/atomic";
import { Grid } from "@mui/material";
import { Collapse } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Container = css`
  width: 100%;

  display: flex;
  justify-content: center;

  padding-bottom: 50px;

  background-image: linear-gradient(360deg, #020710 0%, #02071000 100%);
`;

const BodyWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;

  background-color: #041227;
  padding: 60px 80px;

  @media (max-width: 600px) {
    padding: 50px 15px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 500px;
`;

const Title = styled.div`
  font-size: 43px;
  font-weight: 600;
  font-family: "Raleway", Sans-serif;

  span {
    color: #7b7b7b;
  }

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

const SubLabel = styled.div`
  text-transform: uppercase;
  font-size: 16px;
  color: #2cbca5;
  font-weight: 700;
  font-family: "Inter", Sans-serif;
  letter-spacing: 1px;
  line-height: 1.4em;

  @media (max-width: 900px) {
    font-size: 14px;
  }
`;

const Description = styled.div`
  font-size: 20px;
  color: #b3b4b7;
  max-width: 900px;
  line-height: 1.9em;

  @media (max-width: 900px) {
    font-size: 16px;
  }
`;

const QuestionContainer = styled.div`
  text-align: left;
`;

const QuestionItemContainer = styled.div`
  padding: 20px;
  border-bottom: 1px solid #7f7f7f;
  break-inside: avoid-column;
  height: fit-content;
`;

const QuestionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  cursor: pointer;
  user-select: none;
  align-items: center;
  gap: 20px;
`;

const QuestionTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  transition: all 200ms;

  color: ${(props) => (props.isExpand ? "#36BB91" : "#fff")};
`;

const QuestionBody = styled.div`
  font-size: 16px;
  color: #b3b4b7;
  max-width: 900px;
  line-height: 1.9em;
  margin-top: 20px;

  @media (max-width: 900px) {
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

const Question_List = [
  {
    title: "What is blockchain?",
    body: "A blockchain is a decentralized, distributed and public digital ledger that is used to record transactions across many computers. It can be used to create an immutable record of documents and ensure that they have not been tampered with or altered since they were added to the blockchain.",
  },
  {
    title: "Which blockchain is used by Blockproof to store my proof?",
    body: "Blockproof uses Polygon. You can learn more about Polygon at: <a href='https://polygon.technology/' target='_blank'>https://polygon.technology/</a>",
  },
  {
    title:
      "Is my right protected by law for the creations registered on blockchain?",
    body: "The legal implications of using blockchain as evidence in court may vary depending on jurisdiction in different countries. You may need to consult with legal experts before using the records as evidence in legal proceedings.",
  },
  {
    title: "What can I do if I lost the passcode of my proof?",
    body: "Unfortunately, there is nothing we can do. Only your passcode can decrypt the proof, and that is the intention of protecting the data privacy in the first place.",
  },
  {
    title:
      "How do you protect the privacy of my proof when I enable the encryption?",
    body: "Firstly, we will encrypt your data using AES256 so that no one can see the content without a passcode. Also, all the encryption/decryption processes are done on the web client, and your original data won’t be disclosed.",
  },
  {
    title:
      "I've uploaded a wrong file in my proof. Can I update the file or delete the proof?",
    body: "As the proof is stored in blockchain, due to the immutable and indelible nature of blockchain, you cannot update or delete your proof.",
  },
];

const QuestionItem = (props) => {
  const { title, body } = props;
  const [isExpand, setIsExpand] = useState(false);

  return (
    <QuestionItemContainer>
      <QuestionHeader onClick={() => setIsExpand(!isExpand)}>
        <QuestionTitle isExpand={isExpand}>{title}</QuestionTitle>
        <KeyboardArrowDownIcon
          style={{
            color: isExpand ? "#36BB91" : "#fff",
            transition: "all 450ms ease",
            transform: isExpand ? "rotate(-180deg)" : "rotate(0deg)",
          }}
        />
      </QuestionHeader>
      <Collapse in={isExpand}>
        <QuestionBody dangerouslySetInnerHTML={{ __html: body }}></QuestionBody>
        {/* <QuestionBody>{body}</QuestionBody> */}
      </Collapse>
    </QuestionItemContainer>
  );
};

const StyledGridQuestionContent = (props) => {
  const { questionList } = props;

  const questionPerColumn = Math.ceil(questionList.length / 2) || 0;

  if (questionList.length > 0)
    return (
      <Grid
        container
        spacing={{ xs: 0, md: 5 }}
        sx={{ flexDirection: { xs: "column", md: "row" } }}
      >
        <Grid container item xs={12} md={6} style={{ height: "fit-content" }}>
          {questionList.slice(0, questionPerColumn).map((item, index) => (
            <QuestionItem key={index} title={item.title} body={item.body} />
          ))}
        </Grid>
        <Grid container item xs={12} md={6} style={{ height: "fit-content" }}>
          {questionList
            .slice(questionPerColumn, questionList.length)
            .map((item, index) => (
              <QuestionItem key={index} title={item.title} body={item.body} />
            ))}
        </Grid>
      </Grid>
    );
  else return null;
};

const QASection = () => {
  return (
    <div className={Container}>
      <Grid
        container
        style={{ maxWidth: "1200px", margin: "0 30px" }}
        alignItems="center"
      >
        <BodyWrapper>
          <Header>
            <SubLabel>FAQ</SubLabel>
            <Title>Still have questions?</Title>
            <Description>
              Your creation is valuable. We are here to help you to protect your
              rights.
            </Description>
          </Header>
          <QuestionContainer>
            <StyledGridQuestionContent questionList={Question_List} />
          </QuestionContainer>
        </BodyWrapper>
      </Grid>
    </div>
  );
};

export default QASection;
