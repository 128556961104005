export default function decrypt(text, key) {
    try {
        const CryptoJS = require("crypto-js");
        var ciphertext = CryptoJS.AES.decrypt(text, key).toString(CryptoJS.enc.Utf8);
        return ciphertext;
    } catch(e){
        console.log('kazaf decrypt fail', text, e);
        return null;
    }
}

// let text = "U2FsdGVkX1+lQarBoonvIunDLfKHicP2L2UxhkjAVm8="
// let key = "ZVfbyOOsYXpVmFQRVGL2OSGMs3lcV8tK"
// console.log(decrypt(text, key));