import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { css } from "@linaria/atomic";
import { Grid } from "@mui/material";
import ExampleImageSrc from "../../assets/example.png";
import LinkButton from "../LinkButton";
import constants from "../../constants";

const Container = css`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const LeftView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  text-align: left;
  max-width: 550px;

  @media (max-width: 900px) {
    align-items: center;
    text-align: center;
    max-width: 100%;
  }
`;

const RightView = styled.div`
  max-width: 600px;
  max-height: 600px;
  margin-left: auto;
  margin-right: auto;
`;

const SubLabel = styled.div`
  text-transform: uppercase;
  font-size: 16px;
  color: #2cbca5;
  font-weight: 700;
  font-family: "Inter", Sans-serif;
  letter-spacing: 1px;
  line-height: 1.4em;

  @media (max-width: 900px) {
    font-size: 14px;
  }
`;

const Title = styled.div`
  font-size: 43px;
  font-weight: 600;
  font-family: "Raleway", Sans-serif;
  line-height: 1.3em;
  color: #fff;

  @media (max-width: 900px) {
    font-size: 32px;
  }
`;

const Description = styled.div`
  font-size: 20px;
  color: #2cbca5;
  max-width: 900px;
  line-height: 1.9em;

  @media (max-width: 900px) {
    font-size: 16px;
  }
`;

const Button = styled(Link)`
  font-size: 20px;
  letter-spacing: 0.5px;
  color: #ffffff;
  padding: 18px 44px;
  border-radius: 10px;
  border: 1px solid #fff;
  cursor: pointer;
  text-decoration: none;
  transition: color 450ms, background-color 450ms;
  font-family: "Inter", sans-serif;
  font-weight: 500;

  &:hover {
    background-color: #fff;
    color: #000;
  }

  @media (max-width: 900px) {
    font-size: 16px;
  }
`;

const ExampleSection = () => {
  return (
    <div className={Container}>
      <Grid
        container
        style={{ maxWidth: "1200px", margin: "0 30px", alignItems: "center" }}
        sx={{ flexDirection: { xs: "column", md: "row" } }}
        spacing={2}
      >
        <Grid container item xs={12} md={6}>
          <LeftView>
            <SubLabel>Sample</SubLabel>
            <Title>An original music proof in blockchain</Title>
            <Description>
              User can claim original creator of his music. It's not a copy of
              similar music published after the time registered in blockchain.
            </Description>
            <div
              style={{
                display: "flex",
              }}
            >
              <LinkButton
                to={`/record/${constants.SAMPLE_TX}`}
                noGrowEffect={false}
              >
                View sample
              </LinkButton>
            </div>
          </LeftView>
        </Grid>
        <Grid container item xs={12} md={6}>
          <RightView>
            <img
              src={ExampleImageSrc}
              alt="example"
              style={{ width: "100%" }}
            />
          </RightView>
        </Grid>
      </Grid>
    </div>
  );
};

export default ExampleSection;
