import { Link } from "react-router-dom";
import styled from "@emotion/styled";

const Container = styled.div`
  width: 100%;
  background-color: #171e21;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Inter", Sans-serif;
`;

const Wrapper = styled.div`
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 0;
`;

const BodyWrapper = styled.div`
  margin: 0 50px;
  padding-top: 20px;
  padding-bottom: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    gap: 50px;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  gap: 15px;
`;

const Logo = styled.div`
  color: #fff;
  font-size: 30px;
  font-weight: 700;
`;

const Description = styled.div`
  color: #fff;
  font-size: 15px;
  line-height: 1.5;

  @media (min-width: 1200px) {
    max-width: 90%;
  }
`;

const SectionHeader = styled.div`
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 18px;
  padding-left: 8px;
  border-left: 3px solid #36bb91;

  @media (max-width: 1200px) {
    margin-bottom: 0;
  }
`;

const CopyrightWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 50px;

  font-size: 13px;
  color: #9097aa;

  @media (max-width: 1200px) {
    flex-direction: column-reverse;
    gap: 20px;
    justify-content: center;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: 1200px) {
    margin-bottom: 16px;
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: #fff;
`;

const LinkItem = styled(StyledLink)`
  color: #fff;
  text-decoration: none;
  transition: all 450ms;
  white-space: nowrap;

  &:hover {
    color: #36bb91;
  }
`;

const LinkItems = [
  { id: "home", label: "Home", path: "/" },
  { id: "about", label: "About", path: "https://www.zensis.com/about/" },
  // { id: "our-team", label: "Our Team", path: "/our-team" },
  // { id: "shop", label: "Shop", path: "/shop" },
  // { id: "blog", label: "Blog", path: "/blog" },
  // { id: "contact", label: "Contact Us", path: "/contact" },
];

const Footer = () => {
  return (
    <Container>
      <Wrapper>
        <BodyWrapper>
          <SectionContainer>
            <Logo>Blockproof</Logo>
            <Description>
              Proof for ownership and existence for your creation in blockchain.
              Blockproof is a service provided by{" "}
              <a href="https://www.zensis.com" target="_blank">
                Zensis
              </a>
              .
            </Description>
          </SectionContainer>
          <SectionContainer>
            <SectionHeader>Contact Us</SectionHeader>
            <Description>
              <a href="mailto:service@zensis.com">service@zensis.com</a>
            </Description>
          </SectionContainer>
        </BodyWrapper>
        <CopyrightWrapper>
          <div>Copyright ©2023 Blockproof. All rights reserved.</div>
          <LinkContainer>
            <LinkItem key="home" href="/">
              Home
            </LinkItem>
            <LinkItem
              key="about"
              target="_blank"
              href="https://www.zensis.com/about/"
            >
              About
            </LinkItem>
            {/* {LinkItems.map((item) => (
              <LinkItem key={item.id} to={item.path}>
                {item.label}
              </LinkItem>
            ))} */}
          </LinkContainer>
        </CopyrightWrapper>
      </Wrapper>
    </Container>
  );
};

export default Footer;
