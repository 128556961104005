import Button from "../components/Button";
import TextField from "../components/TextField";
import { styled } from "@linaria/react";
import { useRef, useState } from "react";
import constants from "../constants";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  FormContainer,
  FormTitle,
  FormSubTitle,
  FormQuestionContainer,
} from "../components/Form";
import CircularProgress from "@mui/material/CircularProgress";

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0 30px;
`;

const RecordLink = styled.a`
  textdecoration: underline;
  cursor: pointer;
  color: #ffffff7a;
  &:hover {
    color: #36bb91;
  }
`;

const EmailRecord = () => {
  const navigate = useNavigate();
  let emailInputRef = useRef(null);

  const [transactions, setTransactions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleRetreive = async () => {
    if (emailInputRef.current.value === "") {
      setError("Please enter an email address");
      return;
    }
    setIsLoading(true);
    setError("");
    const email = emailInputRef.current.value;

    const response = await fetch(
      `${constants.API_ENDPOINT}/order/email/${encodeURIComponent(
        email
      )}/transactions`
    );
    const result = await response.json();
    if (result.success) {
      setIsLoading(false);
      setTransactions(result.data);
    }
  };

  return (
    <Container>
      <FormContainer>
        <FormTitle>Search transaction by owner's email address</FormTitle>
        {isLoading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <FormQuestionContainer>
              <TextField
                label="Emaill address to check"
                name="email"
                inputRef={emailInputRef}
                error={error !== "" ? true : false}
                helperText={error !== "" ? error : null}
              />
              <FormSubTitle>
                *Only non-encrypted transaction can be searched
              </FormSubTitle>
            </FormQuestionContainer>
            <Button onClick={handleRetreive}>Retreive</Button>
            {transactions ? (
              transactions.length > 0 ? (
                transactions.map((transaction) => {
                  return (
                    <RecordLink
                      href={"/record/" + transaction.hash}
                      target="_blank"
                    >
                      {transaction.hash}
                    </RecordLink>
                  );
                })
              ) : (
                <div style={{ color: "#ffffff7a" }}>No results</div>
              )
            ) : (
              <div />
            )}
          </>
        )}
      </FormContainer>
    </Container>
  );
};
export default EmailRecord;
