import fetch from 'unfetch';
import CONSTANTS from '../constants';
import encrypt from './encrypt';

function sendFile(presignedurl, type, file, progressCallback) {
    return new Promise((resolve, reject) => {
        // from http://blog.rudikovac.com/react-native-upload-any-file-to-s3-with-a-presigned-url/
        const xhr = new XMLHttpRequest();
        xhr.upload.addEventListener(
            'progress',
            (e) => {
                // handle notifications about upload progress: e.loaded / e.total
                console.log('progress');
                console.log(e);
                // onProgress(e);
                if (typeof progressCallback == 'function') {
                    progressCallback(e);
                }
            },
            false,
        );
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                resolve({ status: xhr.status, response: xhr.response });
            }
        };
        xhr.open('PUT', presignedurl);
        xhr.setRequestHeader('X-Amz-ACL', 'public-read');
        if (type == "html")
            xhr.setRequestHeader('Content-type', 'text/html;charset=UTF-8');
        else if (type == "pdf")
            xhr.setRequestHeader('Content-type', 'application/pdf');
        else
            xhr.setRequestHeader('Content-type', ' '); // it has to be a space char
        xhr.send(file);
    });
}

const uploadFileAsData = async (file) => {
    return await new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = (e) => {
            resolve(fileReader.result);
        }
    })
}

const uploadFileWithEncryption = async (file, key) => {
    return await new Promise((resolve, reject) => {
        fetch(`${CONSTANTS.API_ENDPOINT}/s3/presignedUrl`)
            .then(r => r.json())
            .then(data => {
                console.log('kazaf presignedurl result', data);
                if (data.url) {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file);
                    fileReader.onload = (e) => {
                        let response = null;
                        console.log('kazaf raw img', e.target.result)
                        sendFile(data.url, file.type, encrypt(e.target.result, key), (progress) => {
                            console.log('kazaf uplaod progress, progress');
                        })
                            .then(responseObject => {
                                // Saving response for later use in lower scopes
                                console.log('kazaf uplaod response', responseObject);
                                if (responseObject.status < 200 || responseObject.status >= 300) {
                                    // Throw error
                                    response = responseObject
                                    throw responseObject;
                                } else {
                                    resolve(data.fileKey);
                                }
                            })
                            .catch(error => {
                                console.log('kazaf uplaod error', error);
                                if (response) {
                                    console.log(`Request failed with status ${response.status}.`);
                                } else {
                                    console.log(`Request failed.`);
                                }
                                reject(error);
                            });
                    }
                }
            })
    });
}
const uploadFile = async (file) => {
    return await new Promise((resolve, reject) => {
        fetch(`${CONSTANTS.API_ENDPOINT}/s3/presignedUrl`)
            .then(r => r.json())
            .then(data => {
                console.log('kazaf presignedurl result', data);
                if (data.url) {
                    const fileReader = new FileReader();
                    fileReader.readAsArrayBuffer(file);
                    fileReader.onload = (e) => {
                        let response = null;
                        sendFile(data.url, file.type, e.target.result, (progress) => {
                            console.log('kazaf uplaod progress, progress');
                        })
                            .then(responseObject => {
                                // Saving response for later use in lower scopes
                                console.log('kazaf uplaod response', responseObject);
                                if (responseObject.status < 200 || responseObject.status >= 300) {
                                    // Throw error
                                    response = responseObject
                                    throw responseObject;
                                } else {
                                    resolve(data.fileKey);
                                }
                            })
                            .catch(error => {
                                console.log('kazaf uplaod error', error);
                                if (response) {
                                    console.log(`Request failed with status ${response.status}.`);
                                } else {
                                    console.log(`Request failed.`);
                                }
                                reject(error);
                            });
                    }
                }
            })
    });
}

export {
    uploadFile,
    uploadFileWithEncryption,
    uploadFileAsData
}