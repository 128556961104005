import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import styled from "@emotion/styled";
import { Collapse } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useWindowDimensions from "../../utils/windowDimensions";
import constants from "../../constants";
import LogoImg from "../../assets/logo.svg";

const Nav = styled.div`
  position: fixed;
  width: 100%;
  background: linear-gradient(180deg, rgb(0, 0, 0, 1) 0%, rgb(0, 0, 0, 0) 100%);
  display: flex;
  justify-content: center;
  padding-top: 24px;
  z-index: 9999;

  @media (max-width: calc(1200px - 1px)) {
    position: relative;
    background-color: #000;
    padding: 0;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: 1200px;
  width: 100%;

  @media (max-width: calc(1200px - 1px)) {
    flex-direction: column;
    // height: 100vh;
    padding: 0 16px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: calc(1200px - 1px)) {
    padding: 20px 0;
    width: 100%;
  }

  @media (max-width: 570px) {
    padding: 9px 1rem;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #fff;
  display: inline-flex;
`;

const NavLink = styled(StyledLink)`
  width: 100%;
  height: 100%;
  align-items: center;
  gap: 6px;

  font-size: 15px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  white-space: nowrap;
  flex-wrap: nowrap;

  /** Desktop view **/
  @media (min-width: 1200px) {
    span {
      transition: color 450ms;
    }

    .icon {
      transition: all 450ms;
    }

    &:hover {
      span {
        color: #36bb91;
      }

      .icon {
        transform: rotate(-180deg);
        color: #36bb91;
      }
    }
  }

  /** Mobile view **/
  @media (max-width: calc(1200px - 1px)) {
    border-top: 1px solid #13171f;
    border-radius: 8px;
    height: initial;

    &:last-of-type {
      border-bottom: 1px solid #13171f;
    }

    span {
      padding: 16px 0;
      padding-left: 16px;
      transition: ${({ mobileSubMenuIsOpen }) =>
        mobileSubMenuIsOpen ? "color 450ms" : "none"};
      color: ${({ mobileSubMenuIsOpen }) =>
        mobileSubMenuIsOpen ? "#36bb91" : "inherit"};
    }

    .icon {
      transition: ${({ mobileSubMenuIsOpen }) =>
        mobileSubMenuIsOpen ? "all 450ms" : "rotate 450ms"};
      color: ${({ mobileSubMenuIsOpen }) =>
        mobileSubMenuIsOpen ? "#36bb91" : "inherit"};
      rotate: ${({ mobileSubMenuIsOpen }) =>
        mobileSubMenuIsOpen ? "-180deg" : "0"};
    }

    &:hover {
      background-color: #13171f;

      span,
      .icon {
        color: #36bb91;
      }
    }
  }
`;

const SubNavLink = styled(NavLink)`
  span {
    width: 100%;
    padding: 16px;
    text-align: start;
    border-radius: 8px;
  }

  &:hover {
    span {
      background-color: #13171f;
      color: inherit;
    }
  }

  @media (max-width: calc(1200px - 1px)) {
    border: none;

    span {
      color: #b6bdc5;
      transition: none;
    }

    &:hover {
      span {
        background-color: #11191c;
        color: #36bb91;
      }
    }
  }
`;

const StyledMenuIcon = styled(MenuIcon)`
  padding: 0 12px;

  @media (min-width: 1200px) {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  @media (max-width: calc(1200px - 1px)) {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-right: 59px;
  }
`;

const Logo = styled(StyledLink)`
  font-size: 28px;
  font-weight: 800;
  cursor: pointer;
  align-items: center;

  @media (max-width: 570px) {
    font-weight: 800;
    font-size: 21px;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;

  @media (min-width: 1200px) {
    justify-content: flex-end;
    flex-wrap: nowrap;
    flex-shrink: 1;
  }

  @media (max-width: calc(1200px - 1px)) {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;

  @media (max-width: calc(1200px - 1px)) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 0;
  }
`;

const SubLinkContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1200px) {
    background-color: #141d21;
    border-radius: 0 0 8px 8px;
    padding: 16px 8px;
    border-top: 2px solid #36bb91;
    box-shadow: 0 0.5rem 1.875rem rgb(0 0 0 / 15%);
  }

  @media (max-width: calc(1200px - 1px)) {
    width: 100%;
  }
`;

const LinkItem = styled.div`
  width: 100%;
  height: 100%;

  @media (min-width: 1200px) {
    ${SubLinkContainer} {
      position: absolute;
      top: 200%;
      opacity: 0;
      visibility: hidden;
    }

    &:hover {
      ${SubLinkContainer} {
        display: block;
        opacity: 1;
        visibility: visible;
        top: 100%;
        transition: all 450ms cubic-bezier(0.165, 0.84, 0.44, 1);
      }
    }
  }

  @media (max-width: calc(1200px - 1px)) {
    display: flex;
    flex-direction: column;

    ${SubLinkContainer} {
    }
  }
`;

const ActionButton = styled(StyledLink)`
  font-family: "Inter", sans-serif;
  font-weight: 500;
  border: 1px solid #ffffff80;
  background-color: transparent;
  color: #fff;
  padding: 9px 22px;
  border-radius: 15px;
  cursor: pointer;
  transition: color 450ms, background 450ms;
  white-space: nowrap;

  &:hover {
    background-color: #fff;
    color: #000;
  }
`;

const LinkItems = [
  { id: "home", label: "Home", path: "/" },
  {
    id: "sample",
    label: "Sample",
    path: "/record/" + constants.SAMPLE_TX,
  },
  {
    id: "transactionId",
    label: "Retrieval",
    path: "/record",
  },
];

const MenuBar = (props) => {
  const { setMenuIsOpen } = props;
  const windowDimensions = useWindowDimensions();

  const handleLinkItemOnClick = (props) => {
    const { hasChild, onClick } = props;

    if (!hasChild) {
      setMenuIsOpen && setMenuIsOpen((open) => !open);
    }
    onClick && onClick();
  };

  /** 2nd level menu of a parent nav item  */
  const SubMenu = (props) => {
    const { child } = props;

    return (
      <SubLinkContainer>
        {child.map((subItem, subIndex) => (
          <LinkItem key={subIndex} onClick={handleLinkItemOnClick}>
            <SubNavLink to={subItem.path}>
              <span>{subItem.label}</span>
            </SubNavLink>
          </LinkItem>
        ))}
      </SubLinkContainer>
    );
  };

  const NavLinkItem = (props) => {
    const { item, index } = props;

    const [mobileSubMenuIsOpen, setMobileSubMenuIsOpen] = useState(false);

    return (
      <LinkItem
        key={index}
        onClick={() =>
          handleLinkItemOnClick({
            onClick: setMobileSubMenuIsOpen((open) => !open),
            hasChild: item.child && item.child.length > 0,
          })
        }
      >
        <NavLink
          to={item.child ? null : item.path}
          mobileSubMenuIsOpen={mobileSubMenuIsOpen}
        >
          <span>{item.label}</span>
          {item.child && <KeyboardArrowDownIcon className="icon" />}
        </NavLink>
        {item.child ? (
          windowDimensions.width < 1200 ? (
            <Collapse in={mobileSubMenuIsOpen} unmountOnExit>
              <SubMenu child={item.child} />
            </Collapse>
          ) : (
            <SubMenu child={item.child} />
          )
        ) : null}
      </LinkItem>
    );
  };

  return (
    <MenuContainer>
      <LinkContainer>
        {LinkItems.map((item, index) => (
          <NavLinkItem item={item} index={index} key={item.id} />
        ))}
      </LinkContainer>
      <div>
        <ActionButton
          to="/create"
          onClick={() => setMenuIsOpen((open) => !open)}
        >
          Create Now
        </ActionButton>
      </div>
    </MenuContainer>
  );
};

const Navbar = (props) => {
  const { isOpen, setIsOpen } = props;

  const windowDimensions = useWindowDimensions();
  // const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (windowDimensions.width >= 1200) {
      setIsOpen && setIsOpen(false);
    }
  }, [setIsOpen, windowDimensions.width]);

  return (
    <Nav>
      <Container>
        <LogoContainer>
          <StyledMenuIcon
            color={"white"}
            fontSize={"large"}
            onClick={() => setIsOpen(!isOpen)}
          />
          <LogoWrapper>
            <Logo to="/">
              <img width={200} src={LogoImg} />
            </Logo>
          </LogoWrapper>
        </LogoContainer>
        {windowDimensions.width < 1200 ? (
          <Collapse in={isOpen} unmountOnExit style={{ width: "100%" }}>
            <MenuBar setMenuIsOpen={setIsOpen} />
          </Collapse>
        ) : (
          <MenuBar />
        )}
      </Container>
    </Nav>
  );
};

export default Navbar;
