import styled from "@emotion/styled";
import { css } from "@linaria/atomic";
import { Grid } from "@mui/material";

const Container = css`
  width: 100%;

  display: flex;
  justify-content: center;
`;

const BodyWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

const SubLabel = styled.div`
  text-transform: uppercase;
  font-size: 16px;
  color: #2cbca5;
  font-weight: 700;
  font-family: "Inter", Sans-serif;
  letter-spacing: 1px;
  line-height: 1.4em;

  @media (max-width: 900px) {
    font-size: 14px;
  }
`;

const Title = styled.div`
  font-size: 43px;
  font-weight: 600;
  font-family: "Raleway", Sans-serif;
  line-height: 1.3em;

  @media (max-width: 900px) {
    font-size: 32px;
  }
`;

const Description = styled.div`
  font-size: 20px;
  color: #2cbca5;
  max-width: 900px;
  line-height: 1.9em;

  @media (max-width: 900px) {
    font-size: 16px;
  }
`;

const IntroSection = () => {
  return (
    <div className={Container}>
      <Grid
        container
        style={{ maxWidth: "1200px", margin: "0 30px" }}
        alignItems="center"
      >
        <BodyWrapper>
          <SubLabel>Upload, Retrieve, Prove</SubLabel>
          <Title>
            Composed a song or written a will, <br />
            need proof for ownership and existence <br />
            of your creation？
          </Title>
          <Description>
            By making use of the properties that data cannot be altered or
            deleted in blockchain, we help you to securely register documents in
            blockchain, creating a tamper-proof record of their existence at the
            time it's being written in the blockchain. No one else can claim an
            earlier creation in the blockchain by copying.
          </Description>
        </BodyWrapper>
      </Grid>
    </div>
  );
};

export default IntroSection;
