import styled from '@emotion/styled';

const Container = styled.div`
    width: 100%;
    height: 100%;
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0px;

    @media (max-width: calc(1200px - 1px)) {
        padding: 50px 0px;
    }
`

const Wrapper = styled.div`
    width: 100%;
    max-width: 1200px;
    display: flex;
`

const Layout = ({ children }) => {
    return (
        <Container>
            <Wrapper>
                {children}
            </Wrapper>
        </Container>
    )
}

export default Layout;