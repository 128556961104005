import Button from "../components/Button";
import TextField from "../components/TextField";
import { styled } from "@linaria/react";
import { cx } from "@linaria/core";
import { css } from "@linaria/atomic";
import { useState } from "react";
import constants from "../constants";
import { useNavigate, useParams } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const Container = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  > div {
    width: 700px;
  }
`;

const PayPalConfig = {
  "client-id": constants.PAYPAL_CLIENT_ID,
  currency: "USD",
};

const SamplePayment = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <div className={Container}>
      <PayPalScriptProvider options={PayPalConfig}>
        <PayPalButtons
          createOrder={() => {
            return fetch(`${constants.API_ENDPOINT}/payment/${id}/checkout`, {
              method: "POST",
              headers: {
                "Content-type": "application/json",
              },
            })
              .then((response) => response.json())
              .then((order) => order.data.id);
          }}
          onApprove={(data, actions) => {
            return fetch(
              `${constants.API_ENDPOINT}/payment/${id}/callback/${data.orderID}`,
              {
                method: "POST",
                headers: {
                  "Content-type": "application/json",
                },
              }
            )
              .then((response) => response.json())
              .then((orderData) => {
                console.log("Capture result", orderData);
                // Successful capture! For dev/demo purposes:
                if (orderData.data.status == "Paid") {
                  navigate("/payment-complete/" + id, { replace: true });
                } else {
                  alert(`Payment failed`);
                }
              });
          }}
          onCancel={() => alert(`Payment cancelled`)}
          onError={(err) => alert(`Payment err`, err)}
        />
      </PayPalScriptProvider>
    </div>
  );
};
export default SamplePayment;
