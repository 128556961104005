import { useState } from "react";
import styled from "@emotion/styled";
import Button from "../Button";
import TextField from "../TextField";
import UploadButton from "../FileUpload";
import Switch from "../Switch";
import { useFormik } from "formik";
import * as yup from "yup";
import encryptText from "../../utils/encrypt";
import { uploadFile, uploadFileWithEncryption } from "../../utils/upload";
import constants from "../../constants";
import {
  FormContainer,
  FormTitle,
  FormSubTitle,
  FormQuestionContainer,
} from "../Form";

const Label = styled.div`
  color: #8d8e92;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  text-align: left;
`;

const validationSchema = yup.object().shape({
  file: yup.mixed().required("Required"),
  name: yup.string().required("Required"),
  email: yup.string().email("Invalid email").required("Required"),
  phone: yup.string().required("Required"),
  description: yup.string().required("Required"),
  encrypt: yup.boolean(),
  passcode: yup.string().when("encrypt", {
    is: (val) => val === true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.nullable(),
  }),
});

const PasscodeComponent = (props) => {
  const { disabled, formik, onChange } = props;
  const [isEncrypt, setIsEncrypt] = useState(false);

  const handleChange = (e) => {
    const encrypt = e.target.checked;
    setIsEncrypt(encrypt);
    onChange(encrypt);
  };

  return (
    <>
      <Switch
        name="encrypt"
        label="Encryption"
        disabled={disabled}
        onChange={handleChange}
        checked={isEncrypt}
      />
      {isEncrypt && (
        <TextField
          name="passcode"
          label="Passcode (Please write down this passcode for future proof retrieval)"
          disabled={disabled}
          value={formik.values.passcode}
          onChange={formik.handleChange}
          error={formik.touched.passcode && Boolean(formik.errors.passcode)}
          helperText={formik.touched.passcode && formik.errors.passcode}
        />
      )}
    </>
  );
};

const UploadForm = () => {
  const [isConfirm, setIsConfirm] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);

  console.log("kazaf UploadForm");
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      description: "",
      passcode: "",
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      console.log("==formValue", values);
      setIsConfirm(true);
    },
  });

  const handleOnSubmit = async (formValues) => {
    let fileUrl = null;
    let payload = null;

    const { file, encrypt, passcode } = formValues;

    const body = {
      name: formValues.name,
      email: formValues.email,
      phone: formValues.phone,
      description: formValues.description || "",
    };

    if (encrypt && passcode) {
      let encrypted = encryptText(JSON.stringify(body), passcode);
      payload = {
        encrypted,
      };
      fileUrl = await uploadFileWithEncryption(file, passcode);
    } else {
      fileUrl = await uploadFile(file);
      payload = body;
    }
    // upload file
    const response = await fetch(`${constants.API_ENDPOINT}/order`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        body: payload,
        file: fileUrl,
        email: formValues.email,
      }),
    });
    const result = await response.json();
    console.log("result", result);
    if (result.success) {
      window.location = "/pay/" + result.data._id;
    }
  };

  return (
    <FormContainer onSubmit={formik.handleSubmit}>
      <FormTitle>
        Create a proof for a creation
        <span style={{ color: "#2cbca5", marginLeft: 10 }}> (USD 10)</span>
      </FormTitle>
      <div>
        <Label>
          You are going to upload a digital file (the creation) required to
          create a proof. Input info to describe what the creation is about and
          who the creator is. All data will be permanently written on the
          blockchain. As data in blockchain is public, if you want to make the
          data private, you could enable the encryption.
        </Label>
      </div>
      <FormQuestionContainer>
        <Label>File (max. file size 5MB)</Label>
        <UploadButton
          value={formik.values.file}
          disabled={isConfirm}
          onChange={(e) => {
            const file = e == null ? null : e.target.files[0];
            if (file && file.size > 5242880) {
              setFileSizeError(true);
            } else {
              setFileSizeError(false);
              formik.setFieldValue("file", file);
            }
          }}
          error={
            fileSizeError || (formik.errors && formik.errors.file)
              ? true
              : false
          }
          helperText={
            fileSizeError
              ? "File size limit exceeded"
              : formik.errors && formik.errors.file
              ? formik.errors.file
              : null
          }
        />
        <TextField
          name="description"
          label="Description"
          multiline={true}
          disabled={isConfirm}
          onChange={formik.handleChange}
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
          helperText={formik.touched.description && formik.errors.description}
        />
      </FormQuestionContainer>

      <FormSubTitle>Creator Info</FormSubTitle>
      <FormQuestionContainer>
        <TextField
          name="name"
          label="Name"
          disabled={isConfirm}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <TextField
          name="email"
          label="Email"
          disabled={isConfirm}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          name="phone"
          label="Phone"
          disabled={isConfirm}
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
        />
        <PasscodeComponent
          disabled={isConfirm}
          formik={formik}
          onChange={(val) => formik.setFieldValue("encrypt", val)}
        />
      </FormQuestionContainer>
      {isConfirm ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "20px",
          }}
        >
          <Button
            style={{ width: 160 }}
            label="< Back"
            color="secondary"
            onClick={() => setIsConfirm(false)}
          />
          <Button
            style={{ width: 300 }}
            label="Confirm & pay (USD 10)"
            onClick={() => handleOnSubmit(formik.values)}
          />
        </div>
      ) : (
        <Button label="Next" type="submit" />
      )}
    </FormContainer>
  );
};

export default UploadForm;
