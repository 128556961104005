import styled from "@emotion/styled";
import { css } from "@linaria/atomic";
import { Grid } from "@mui/material";
import LinkButton from "../LinkButton";
import SparkleImage from "../../../src/assets/sparkle.png";

const Container = css`
  width: 100%;
  display: flex;
  justify-content: center;

  background-image: linear-gradient(360deg, #020710 0%, #02071000 100%);
`;

const BodyWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  color: white;

  display: flex;
  flex: 1 auto;
  justify-content: center;
  align-items: center;
  gap: 30px;

  background-color: #2a2b3169;
  padding: 60px 80px;
  border-radius: 10px;
  border: 1px solid #849aaa85;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    padding: 50px 15px;
  }
`;

const LeftView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  text-align: left;

  @media (max-width: 900px) {
    align-items: center;
    text-align: center;
    max-width: 100%;
  }
`;

const RightView = styled.div`
  min-width: 200px;
`;

const Title = styled.div`
  font-size: 43px;
  font-weight: 600;
  font-family: "Raleway", Sans-serif;
  max-width: 510px;
  align-items: center;

  span {
    color: #7b7b7b;
  }

  @media (max-width: 768px) {
    font-size: 32px;
    max-width: 320px;
  }
`;

const Description = styled.div`
  font-size: 20px;
  color: #b3b4b7;
  max-width: 900px;
  line-height: 1.9em;

  @media (max-width: 900px) {
    font-size: 16px;
  }
`;

const StartNowSection = () => {
  return (
    <div className={Container}>
      <Grid
        container
        style={{ maxWidth: "1200px", margin: "0 30px" }}
        alignItems="center"
      >
        <BodyWrapper>
          {/* Left */}
          <LeftView>
            <Title>
              Let's create a proof for your <span>creation</span> now
              <img
                src={SparkleImage}
                style={{ width: "30px", marginLeft: "10px" }}
                alt="Sparkle"
              />
            </Title>
            <Description>
              With our user-friendly interface, you can register your work in
              minutes and access it anytime, anywhere. Don't let your hard work
              go unprotected!
            </Description>
          </LeftView>

          {/* Right */}
          <RightView>
            <div
              style={{
                display: "flex",
              }}
            >
              <LinkButton to="/create" noGrowEffect={false}>
                Create now
              </LinkButton>
            </div>
          </RightView>
        </BodyWrapper>
      </Grid>
    </div>
  );
};

export default StartNowSection;
