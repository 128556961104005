import { useNavigate, useParams } from "react-router-dom";
import { css } from "@linaria/atomic";
import HeroSection from "../components/home/HeroSection";
import IntroSection from "../components/home/IntroSection";
import ExampleSection from "../components/home/ExampleSection";
import StartNowSection from "../components/home/StartNowSection";
import QASection from "../components/home/QASection";

const Container = css`
  width: 100%;
  background-color: #020710;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
`;
const UploadSuccess = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <div className={Container}>
      <HeroSection />
      <IntroSection />
      <ExampleSection />
      <StartNowSection />
      <QASection />
    </div>
  );
};
export default UploadSuccess;
