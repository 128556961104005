import MuiDialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogActions from '@mui/material/DialogActions';
import styled from "@emotion/styled";

const Dialog = styled(MuiDialog)({
    "& .MuiDialog-paper": {
        backgroundColor: "#23262f",
        borderRadius: "16px",
        width: '100%',
        padding: "20px 30px",
    },
})

const DialogContent = styled(MuiDialogContent)`
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #fff;
    padding: 20px 0px;
`

const DialogTitle = styled(MuiDialogTitle)`
    font-family: 'Raleway', sans-serif;
    color: #fff;
    font-weight: 700;
`

const DialogActions = styled(MuiDialogActions)({
    display: "flex",
    justifyContent: "space-between",
})

export { Dialog, DialogContent, DialogTitle, DialogActions }
