import styled from "@emotion/styled";

const FormContainer = styled.form`
    background-color: #23262f;
    border-radius: 10px;
    padding: 40px;

    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;

    @media (max-width: calc(768px - 1px)) {
        padding: 30px 20px;
    }
`

const FormTitle = styled.div`
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: #fff;

    display: flex;
    text-align: left;
`

const FormSubTitle = styled.div`
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-align: left;
`

const FormQuestionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

export {
    FormContainer,
    FormTitle,
    FormSubTitle,
    FormQuestionContainer
}